import { useMemo, useState } from "react";
import type { SearchBoxSuggestion } from "@mapbox/search-js-core";
import { SessionToken } from "@mapbox/search-js-core";
import { useSearchBoxCore } from "@mapbox/search-js-react";
import { debounce } from "lodash";
import { accessToken, searchBoxOptions } from "@/react/helpers/mapboxHelper";

const sessionToken = new SessionToken();

export const useMapbox = () => {
  const [suggestions, setSuggestions] = useState<SearchBoxSuggestion[]>([]);

  const [selectedLocation, setSelectedLocation] =
    useState<SearchBoxSuggestion | null>();

  const [selectedMapboxId, setSelectedMapboxId] = useState<string | null>(null);

  const searchBoxCore = useSearchBoxCore({ accessToken });

  const debouncedSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (value) {
          const response = await searchBoxCore.suggest(value, {
            sessionToken,
            ...searchBoxOptions,
          });
          setSuggestions(response.suggestions);
        } else {
          setSuggestions([]);
        }
      }, 300),
    [searchBoxCore],
  );

  const findSuggestionByMapboxId = (mapboxId: string) =>
    suggestions.find(suggestion => suggestion.mapbox_id === mapboxId);

  const handleSuggestionSelect = (option: string) => {
    setSelectedMapboxId(option);
    try {
      const selectedSuggestion = findSuggestionByMapboxId(option);
      if (!selectedSuggestion) {
        throw new Error("Suggestion not found");
      }
      setSelectedLocation(selectedSuggestion);
      setSuggestions([]);
    } catch (error) {
      console.error(error);
    }
  };

  const locationOptions = suggestions.map(suggestion => ({
    label: suggestion.name,
    value: suggestion.mapbox_id,
    description: [
      suggestion.place_formatted,
      suggestion.context?.region?.name,
      suggestion.context?.country?.name,
    ]
      .filter(Boolean)
      .join(", "),
    originalSuggestion: suggestion,
  }));

  return {
    locationOptions,
    handleSuggestionSelect,
    selectedMapboxId,
    debouncedSearch,
    selectedLocation,
  };
};
